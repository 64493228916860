import React from 'react'
import { graphql, Link } from 'gatsby'
import Layout from '../layouts'
// import style from '../components/post/index.module.css'
// import Author from '../components/post/author'
import InfiniteScroll from 'react-infinite-scroller'
import _ from 'lodash'
import Moment from 'react-moment'
import ads1 from '../images/sample-ad-2.gif'
import ads2 from '../images/sample-ad-3.jpeg'

/**
 * Replaces the typical paginated blog posts to infinite scrolling. Enabled by default.
 * If USE_PAGINATION is set to 'true' in env, this will not page will be discarded
 */
class BlogPage extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      posts: [],
      hasMoreItems: true,
    }
  }

  loadItems(currentPage) {
    const self = this
    const posts = this.props.data.allStrapiPosts.edges.map(post => post.node)
    const postsPerPage = process.env.POST_PER_PAGE || 10
    const postsChunked = _.chunk(posts, postsPerPage)
    const currentPageIndex = currentPage - 1

    var currentPosts = self.state.posts
    postsChunked[currentPageIndex].forEach(post => {
      currentPosts.push(post)
    })

    if (currentPage >= postsChunked.length) {
      self.setState({
        hasMoreItems: false,
      })
    } else {
      self.setState({
        posts: currentPosts,
        hasMoreItems: true,
      })
    }
  }

  render() {
    // const users = this.props.data.allStrapiUsers.edges.map(user => user.node)

    const loader = (
      <div className="loader" key="infinite-scroll-loader">
        Loading ...
      </div>
    )
    var items = []

    this.state.posts.forEach(post => {
      items.push(
        <div className="row news-item">
          <div className="col-md-5" key={post.id}>
            <Link to={post.fields.slug}>
              <div className="overlay">
                <div className="thumb-date post-date" />
              </div>
            </Link>
            <div
              className="news-list-image"
              style={{
                backgroundImage: `url(${post.banner.url})`,
                backgroundSize: 'cover',
                backgroundRepeat: 'no-repeat',
                backgroundPosition: 'center',
              }}
            >
              <div className="overlay">
                <div className="thumb-date post-date">
                  <span className="month">
                    <Moment format="MMM" date={post.createdAt} />
                  </span>
                  <span className="day">
                    <Moment format="DD" date={post.createdAt} />
                  </span>
                  <span className="year">
                    <Moment format="YYYY" date={post.createdAt} />
                  </span>
                </div>
              </div>
            </div>
          </div>
          <div className="col-md-7">
            {post.tags.map(tag => (
              <Link
                to={`tags/${tag.name.replace(/\s+/g, '-').toLowerCase()}`}
                className="headline-span"
              >
                <span key={tag.id}>{tag.name}</span>
              </Link>
            ))}
            <Link to={post.fields.slug} className="news-headline">
              <h4>{post.title}</h4>
            </Link>
            <p>{post.excerpt}</p>
            <Link to={post.fields.slug} className="arrow-link">
              Read More
              <i className="fas fa-long-arrow-alt-right" />
            </Link>
          </div>
        </div>
      )
    })

    return (
      <Layout>
        <InfiniteScroll
          pageStart={0}
          loadMore={this.loadItems.bind(this)}
          hasMore={this.state.hasMoreItems}
          loader={loader}
        >
          <div className="banner-background">
            <center>
              <h1>Latest Articles</h1>
            </center>
          </div>
          <div className="news-list-section">
            <div className="container">
              <div className="row">
                <div className="col-md-8">{items}</div>
                <div className="col-md-4">
                  <span className="headline-span">Trending Now</span>
                  {this.state.posts.map(news => (
                    <div>
                      {news.tags.map(tag => (
                        <div>
                          {tag.name === 'Featured' ? (
                            <div className="row trending-now-item">
                              <div key={news.id} className="col-md-5">
                                <Link to={news.fields.slug}>
                                  <div
                                    className="trending-now-image"
                                    style={{
                                      backgroundImage: `url(${
                                        news.banner.url
                                      })`,
                                      backgroundSize: 'cover',
                                      backgroundRepeat: 'no-repeat',
                                      backgroundPosition: 'center',
                                    }}
                                  >
                                    <div className="date-trending">
                                      <span className="date">
                                        <Moment
                                          format="MMM DD"
                                          date={news.published}
                                        />
                                        <i className="fas fa-long-arrow-alt-right" />
                                      </span>
                                    </div>
                                  </div>
                                </Link>
                              </div>
                              <div className="col-md-7">
                                <Link
                                  to={news.fields.slug}
                                  className="news-headline"
                                >
                                  <h5>{news.title}</h5>
                                </Link>
                              </div>
                            </div>
                          ) : null}
                        </div>
                      ))}
                    </div>
                  ))}
                  <img
                    src={ads1}
                    className="mb-4 img-fluid"
                    alt="First advertisement"
                  />
                  <img
                    src={ads2}
                    className="mb-4 img-fluid"
                    alt="Second advertisement"
                  />
                </div>
              </div>
            </div>
          </div>
        </InfiniteScroll>
      </Layout>
    )
  }
}

export default BlogPage

export const loadMoreQuery = graphql`
  query {
    allStrapiPosts(sort: { fields: [createdAt], order: DESC }) {
      edges {
        node {
          id
          title
          excerpt
          createdAt
          updatedAt
          fields {
            slug
          }
          banner {
            url
          }
          author {
            id
          }
          tags {
            id
            name
          }
          published
        }
      }
    }
    allStrapiUsers {
      edges {
        node {
          id
          email
          profile {
            id
            firstname
            lastname
          }
        }
      }
    }
  }
`
